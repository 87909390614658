<template>
  <div class="Wenbrigade">
    <img src="../../assets/product/Wenbrigade/1.png" class="headerimg" />

    <div class="pain">
      <div class="title">行业痛点</div>
      <div class="painlist">
        <div class="paincard">
          <div class="paimg">
            <img src="../../assets/product/Wenbrigade/2.png" alt="" />
          </div>
          <div class="painnfo">
            <div>沉睡客户多价值低</div>
            <div class="paintext">难以唤醒</div>
          </div>
        </div>

        <div class="paincard">
          <div class="paimg">
            <img src="../../assets/product/Wenbrigade/3.png" alt="" />
          </div>
          <div class="painnfo">
            <div>行业竞争激烈</div>
            <div class="paintext">客户容易流失</div>
          </div>
        </div>

        <div class="paincard">
          <div class="paimg">
            <img src="../../assets/product/Wenbrigade/4.png" alt="" />
          </div>
          <div class="painnfo">
            <div>旅游方案多</div>
            <div class="paintext">客户意向难判断</div>
          </div>
        </div>

        <div class="paincard">
          <div class="paimg">
            <img src="../../assets/product/Wenbrigade/5.png" alt="" />
          </div>
          <div class="painnfo">
            <div>销售流程不规范</div>
            <div class="paintext">业务效率低</div>
          </div>
        </div>
      </div>
    </div>

    <div class="lightspot">
      <div class="title">方案亮点</div>
      <div class="lightspotlist listtop">
        <div class="lightspotcard">
          <div class="lightspottitle">
            <span class="num">01</span
            ><span class="dot">.</span>系统构建营销闭环
          </div>
          <div class="lightspotinfo">
            借助客户通文章、文件夹、海报等功能承载热门路线分享朋友圈，通过访客轨迹，筛选出默默关注旅游路线更新的客户
          </div>
          <div class="lightspotinfo">
            对于已经成功签约的客户，结合客户客户标签、生日提醒、营销短信等个性化功能进行二次营销，以提高复购率
          </div>
        </div>
        <div class="lightspotimg">
          <img src="../../assets/product/Wenbrigade/6.png" alt="" />
        </div>
      </div>

      <div class="lightspotlist">
        <div class="lightspotimg">
          <img src="../../assets/product/Wenbrigade/7.png" alt="" />
        </div>
        <div class="lightspotcard">
          <div class="lightspottitle">
            <span class="num">02</span
            ><span class="dot">.</span>客户信息管理系统
          </div>
          <div class="lightspotinfo">
            设定客户的价格范围、目的地、旅游类型、出游时间、出游记录等字段，将客户进行分层，更精准地推送旅游方案
          </div>
          <div class="lightspotinfo">
            利用文章、文件夹等功能，有序整理旅游路线，上门拜访时可以直接发送给客户，摆脱纸质传单被随手遗弃的烦恼
          </div>
        </div>
      </div>

      <div class="lightspotlist">
        <div class="lightspotcard">
          <div class="lightspottitle">
            <span class="num">03</span
            ><span class="dot">.</span>销售人员业务规范
          </div>
          <div class="lightspotinfo">
            销售过程中将游客跟进情况、签单情况等信息录入系统，统一管理游客资源，即使是销售人员变动、离职也不受影响
          </div>
          <div class="lightspotinfo">
            支持接入邦伲德商城，将规范化的旅游路线上传至后台，销售人员一键选用，系统柜实时反馈商品浏览数据和客户偏好
          </div>
        </div>
        <div class="lightspotimg">
          <img src="../../assets/product/Wenbrigade/8.png" alt="" />
        </div>
      </div>

      <div class="lightspotlist">
        <div class="lightspotimg">
          <img src="../../assets/product/Wenbrigade/9.png" alt="" />
        </div>
        <div class="lightspotcard">
          <div class="lightspottitle">
            <span class="num">04</span
            ><span class="dot">.</span>建立管理监控体系
          </div>
          <div class="lightspotinfo">
            多维度的数据监控体系，管理层可以及时掌握下属员工的业务进展情况，能够有针对性的提供业务指导，提升经营业绩
          </div>
          <div class="lightspotinfo">
            利用任务中心实现全员标准化推广，使每一位员工成为企业的触手，以现有资源进行客户裂变，激发销售的营销获客动力
          </div>
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">从获客到成交，邦伲德客户通一站式解决方案</div>
      <div class="title2">线索获取</div>

      <div class="casetable">
        <div class="caselist">
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/10.png" alt="" />
            </div>
            <div class="casetitle">智能名片</div>
            <div class="caseinfo">
              让客户快速全面了解人、产品、公司，随时随地实现立体化、标准化形象展示
            </div>
          </div>
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/11.png" alt="" />
            </div>
            <div class="casetitle">获客文章</div>
            <div class="caseinfo">
              微信爆文一键转化，优质内容吸引客户阅读转发，托载销售广告轻松获客
            </div>
          </div>
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/12.png" alt="" />
            </div>
            <div class="casetitle">官网展示</div>
            <div class="caseinfo">
              支持在名片中接入轻站小程序、或外部小程序数据，企业文化全面曝光，持续传播品牌价值
            </div>
          </div>
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/13.png" alt="" />
            </div>
            <div class="casetitle">IM即时聊天</div>
            <div class="caseinfo">
              无需添加好友，直接聊天。文章、名片双曝光入口，设置问候欢迎语，主动触达意向客户
            </div>
          </div>
        </div>

        <div class="caselist casetop">
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/14.png" alt="" />
            </div>
            <div class="casetitle">获客视频</div>
            <div class="caseinfo">
              托载销售个人名片与商机雷达，可插入单件商品，轻松实现短视频带货
            </div>
          </div>
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/15.png" alt="" />
            </div>
            <div class="casetitle">企业查询</div>
            <div class="caseinfo">
              查企业、查老板、查合作，企业信息免费获取，一键查询，批量导出企业名单
            </div>
          </div>
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/16.png" alt="" />
            </div>
            <div class="casetitle">获客海报</div>
            <div class="caseinfo">
              自带名片码的营销海报，提供每日问候、节日热点等多种模板，亦适用于企业品牌曝光
            </div>
          </div>
          <div class="casecard">
            <div class="caseimg">
              <img src="../../assets/product/Wenbrigade/17.png" alt="" />
            </div>
            <div class="casetitle">营销活动</div>
            <div class="caseinfo">
              接入邦伲德互动、邦伲德微传单，利用营销活动盘活客户，高效收集意向线索
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="where">
      <div class="title">为什么要选择邦伲德客户通？</div>
      <div class="wherelist">
        <div class="wherecard">
          <div class="wheretitle bacimg">销客管理一体化</div>
          <div class="whereinfo">
            集销售获客、客户管理、成交转化、 销售管理等功能于一体
          </div>
        </div>

        <div class="wherecard">
          <div class="wheretitle bacimg1">邦伲德体系数据打通</div>
          <div class="whereinfo">
            支持接入邦伲德商城、轻站、互动、微传单等产品数据，联动性强
          </div>
        </div>

        <div class="wherecard">
          <div class="wheretitle bacimg2">覆盖微信&企微场景</div>
          <div class="whereinfo">
            可在微信小程序、企微侧边栏中使用 客户通，高度覆盖客户沟通场景
          </div>
        </div>

        <div class="wherecard">
          <div class="wheretitle bacimg3">行业适用性强</div>
          <div class="whereinfo">
            可灵活自定义编辑，覆盖范围广，可
适用于多种行业的营销场景
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.where {
  margin-bottom: 140px;
  .wherelist {
    margin: auto;
    margin-top: 60px;
    width: 73%;
    display: flex;
    justify-content: space-between;
    .bacimg {
      background-image: url("../../assets/product/Wenbrigade/18.png");
    }
     .bacimg1 {
      background-image: url("../../assets/product/Wenbrigade/19.png");
    }
     .bacimg2 {
      background-image: url("../../assets/product/Wenbrigade/20.png");
    }
     .bacimg3 {
      background-image: url("../../assets/product/Wenbrigade/21.png");
    }
    .wherecard {
      width: 325px;
      height: 284px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      .wheretitle {
        width: 326px;
        height: 206px;
        line-height: 206px;
        font-size: 30px;
        font-weight: 500;
        color: #ffffff;
        background-size: 100% 100%;
        text-align: center;
      }
      .whereinfo {
        margin: 20px 34px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}

.case {
  margin: 140px auto;
  padding-top: 87px;
  padding-bottom: 155px;
  background: #f5f7fa;
  .casetable {
    margin: auto;
    margin-top: 60px;
    background: #fff;
    width: 73%;
    .casetop {
      margin-top: 40px;
    }
    .caselist {
      display: flex;
      justify-content: space-between;
      .casecard {
        width: 310px;
        height: 384px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        .caseimg {
          text-align: center;
          margin-top: 60px;
          img {
            width: 100px;
          }
        }
        .casetitle {
          margin-top: 50px;
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }
        .caseinfo {
          margin: auto 19px;
          margin-top: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
        }
      }
    }
  }
}

.lightspot {
  .listtop {
    margin-top: 60px !important;
  }
  .lightspotlist {
    margin: auto;
    margin-bottom: 140px;
    display: flex;
    width: 73%;
    justify-content: space-between;
    align-items: center;
    .lightspotimg {
      img {
        width: 510px;
      }
    }
    .lightspotcard {
      .lightspottitle {
        font-size: 30px;
        font-weight: 500;
        line-height: 70px;
        .num {
          color: #19d9e5;
          font-size: 50px;
        }
        .dot {
          color: #19d9e5;
        }
      }

      .lightspotinfo {
        width: 684px;
        font-size: 24px;
        font-weight: 400;
        color: #616b80;
        line-height: 33px;
        margin-top: 10px;
      }
    }
  }
}

.pain {
  margin-top: 140px;
  margin-bottom: 65px;
  .painlist {
    margin: 60px auto;
    display: flex;
    width: 73%;
    justify-content: space-between;
    .paincard {
      width: 325px;
      height: 326px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      .paimg {
        img {
          width: 325px;
        }
      }
      .painnfo {
        padding: 22px 0px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        .paintext {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
